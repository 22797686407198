import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="DevOps Engineer - Software Careers | MCRO"
    description={`Devops Engineer at MCRO: if you are a forward thinking, problem solver with a strong passion for 
    DevOps and Infrastructure at MCRO you will collaborate with an extremely talented and professional team of 
    engineers that will take your career towards success and will help you grow and deliver the best quality for top 
    clients and innovative products.`}
    metaContent="devops, google cloud services, amazon web services, elastic search, cloud formation, deployments"
    id="devops"
    jobTitle="DevOps Engineer"
    jobDescription={`If you are a forward thinking, problem solver with a strong passion for DevOps
    and Infrastructure at MCRO you will collaborate with an extremely talented and professional team of
    engineers that will boost your career towards success and will help you grow and deliver the best
    quality for top clients and innovative products.`}
    mailHref="mailto:jobs@mcro.tech?subject=DevOps Job Application"
    responsibilities={[
      'Configure and maintain resources on AWS',
      'Maintain infrastructure as code using Terraform, CloudFormation, and/or Ansible',
      'Maintain Dockerfiles',
      'Deploy services to ECS or Kubernetes',
      'Experience with production deployments like Jenkins, CodeDeploy, GitHub Actions',
      'Manage security groups and access controls',
      'Responsible for securely storing information such as passwords and tokens',
      'Use ticket management system such as Jira and Confluence to manage work priorities',
      'Work closely with developers on the team to debug web app issues',
      'Apply networking knowledge to debug upstream issues',
      'Ability to clearly articulate and communicate complex technical ideas to non-DevOps colleagues'
    ]}
    techSkills={[
      'Proficient in Terraform, such as ability to write modules from scratch',
      'Hands-on experience with configuring and maintaining resources on AWS',
      'Hands-on experience using Docker',
      'Hands on',
      'Experience and solid understanding to deploy and maintain container orchestration platforms such as ECS '
      + 'or Kubernetes',
      'Experience with production deployments using Jenkins',
      'Extensive experience with Linux and administratio',
      'Knowledge of networking fundamentals',
      'Experience with and a strong understanding of secret management along with the practice of good security '
      + 'hygiene',
      'Expertise with cloud security, understand the principle of least privilege',
      'Experience with securing S3 buckets and IAM configurations',
      'Understand security groups and access controls',
      'Experience with Atlassian tooling such as Jira and Confluence preferred',
      'Strong written and verbal communication English skills'
    ]}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj Napoca-Office',
      'Private healthcare insurance for employees'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

CareersDetails.defaultProps = {
  data: null
};

export default CareersDetails;

export const query = graphql`
    query devopsEngineerCareerPage {
        file(relativePath: { eq: "careers-details-banner.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 1440)  {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
